
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  onUpdated,
  ref,
  watch,
} from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import SkuInfo from "@/components/layout/SkuInfo.vue";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import {
  CommonFormType,
  CommonHtmlType,
  NumberOrString,
} from "@/core/directive/type/common";
import {
  CommonArrayToString,
  modalShowListener,
  removeReadonly,
} from "@/core/directive/function/common";
import { ElTable } from "element-plus";
import { MenuComponent } from "@/assets/ts/components";
import { ApiBase, ApiMerchant } from "@/core/api";
import { TaggingItem } from "@/core/directive/interface/common";
import {
  draftOrderOverview,
  DraftOrderOverview,
  DraftOrderOverviewRelationItemDraft,
} from "@/core/directive/interface/salesOrder";
import { ProductItemImage } from "@/core/directive/interface/order";
import EmptyData from "@/components/layout/EmptyData.vue";
import EmptyDataAction from "@/components/layout/EmptyDataAction.vue";
import { CurrencyType } from "@/core/directive/type/order";
import { getDiscountPercent } from "@/core/directive/function/order";
import accounting from "accounting-js";
import WindowResize from "@/components/layout/WindowResize.vue";
import QrcodeScanner from "@/components/layout/QrcodeScanner.vue";

export default defineComponent({
  name: "add-sales-order-order-overview-add",
  props: {
    form: {
      type: Object as () => DraftOrderOverview,
      default: () => {
        return Object.assign({}, draftOrderOverview);
      },
    },
    isRaido: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ProductInfo,
    SkuInfo,
    EmptyData,
    WindowResize,
    EmptyDataAction,
    QrcodeScanner,
  },
  emits: ["update-list", "reset-form", "show-sku-modal"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const currentInstance: any = getCurrentInstance();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const loading = ref<boolean>(false);
    const formRef = ref<CommonFormType>(null);
    const addProductItemRef = ref<CommonHtmlType>(null);
    const multipleTableRef = ref<InstanceType<typeof ElTable>>();

    const formData = ref({
      items: [] as DraftOrderOverviewRelationItemDraft[],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      sku_ids: [] as NumberOrString[],
      products: [] as DraftOrderOverviewRelationItemDraft[],
      brand_id: "",
      search_value: "",
      merchant_id: "",
      inventory_type: "",
      channel_id: "",
    });

    const rules = ref({});

    const options = ref({
      brandLoading: false,
      isRequest: false,
      brand: [] as TaggingItem[],
      merchantLoading: false,
      merchant: [] as TaggingItem[],
      channelLoading: false,
      channel: [] as TaggingItem[],
      inventory_type: [
        {
          label: t("salesOrderOverview.onHand"),
          value: "10",
        },
      ] as TaggingItem[],
    });

    watch(
      () => formData.value.items,
      (newVal) => {
        setTableSelect();
      }
    );

    const scanResult = (value) => {
      formData.value.search_value = value;
    }

    const showSkuModal = () => {
      hideModal(addProductItemRef.value);
    };

    const cancelReadonly = (async) => {
      removeReadonly(async, currentInstance);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      getFormInfo();
    };

    const handleFilter = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          getFormInfo();
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const isDisabledSearch = computed(() => {
      let flag = false;
      if (
        formData.value.brand_id === "" &&
        formData.value.search_value.length < 4
      ) {
        flag = true;
      }
      return flag;
    });

    const getSkuListItemImages = computed(() => {
      return (item: DraftOrderOverviewRelationItemDraft) => {
        let arr: ProductItemImage[] = [];
        item.images.map((row) => {
          arr.push({
            id: 0,
            origin_url: row,
          });
        });
        return arr;
      };
    });

    const toggleItemSelect = (item: DraftOrderOverviewRelationItemDraft) => {
      if (formData.value.sku_ids.indexOf(item.entity_id) === -1) {
        if (props.isRaido) {
          formData.value.sku_ids = [item.entity_id];
          formData.value.products = [item];
        } else {
          formData.value.sku_ids.push(item.entity_id);
          formData.value.products.push(item);
        }
      } else {
        const index = formData.value.sku_ids.indexOf(item.entity_id);
        if (index > -1) {
          formData.value.sku_ids.splice(index, 1);
          formData.value.products.splice(index, 1);
        }
      }
    };

    const isChecked = computed(() => {
      return (item) => {
        let flag = false;
        if (formData.value.sku_ids.indexOf(item.entity_id) > -1) {
          flag = true;
        }
        return flag;
      };
    });

    const setTableSelect = () => {
      formData.value.items.forEach(
        (item: DraftOrderOverviewRelationItemDraft) => {
          if (formData.value.sku_ids.indexOf(item.entity_id) > -1) {
            multipleTableRef.value?.toggleRowSelection(item, true);
          }
        }
      );
    };

    const handleSelectionChange = (selection) => {
      if (selection.length === 0 && !loading.value) {
        formData.value.items.map((row) => {
          const index = formData.value.sku_ids.indexOf(row.entity_id);
          if (index > -1) {
            formData.value.sku_ids.splice(index, 1);
            formData.value.products.splice(index, 1);
          }
        });
      } else {
        selection.map((item) => {
          if (formData.value.sku_ids.indexOf(item.entity_id) === -1) {
            formData.value.sku_ids.push(item.entity_id);
            formData.value.products.push(item);
          }
        });
      }
      setTableSelect();
    };

    const handleSelectChange = (selection, row) => {
      const index = formData.value.sku_ids.indexOf(row.entity_id);
      if (index > -1) {
        formData.value.sku_ids.splice(index, 1);
        formData.value.products.splice(index, 1);
      }
    };

    const isSelectable = computed(() => {
      return (item: DraftOrderOverviewRelationItemDraft) => {
        let flag = true;
        // if (item.qty === 0) {
        //   flag = false;
        // }
        return flag;
      };
    });

    const currentPageChange = async (val) => {
      loading.value = true;
      await getShowInfo();
      loading.value = false;
    };

    const isDisabledSubmit = computed(() => {
      let flag = false;
      if (formData.value.sku_ids.length === 0) {
        flag = true;
      }
      return flag;
    });

    const getBrandSourceData = async (value) => {
      options.value.brandLoading = true;
      let params = {
        search_value: value,
      };
      const { data } = await ApiBase.getBrandSourceData(params);
      options.value.brandLoading = false;
      if (data.code == 0) {
        options.value.brand = data.data;
      }
    };

    const debounceBrandSourceSearch = _.debounce(getBrandSourceData, 1000);

    const searchBrandSourceItems = (query: string) => {
      debounceBrandSourceSearch(query);
    };

    const getMerchantSourceData = async (value, id?) => {
      options.value.merchantLoading = true;
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getMerchantSourceData({
        ...params,
      });
      options.value.merchantLoading = false;
      if (data.code == 0) {
        options.value.merchant = data.data;
      }
    };

    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };

    const getSalesChannelsData = async (id) => {
      formData.value.channel_id = "";
      options.value.channel = [];
      options.value.channelLoading = true;
      const { data } = await ApiMerchant.getSalesChannelsData({
        max_item: "all",
        merchant_id: id,
        status: "10",
      });
      options.value.channelLoading = false;
      if (data.code == 0) {
        options.value.channel = data.data;
      }
    };

    const submit = () => {
      const products = formData.value.products;
      products.forEach((item) => {
        item.qty = 1;
        if (props.form.config_id == "2") {
          // item.base_gross_price = getOrderGrossPrice(item.base_price, 0);
          item.discount = -13.44;
          item.tax_rate = 0;
          item.net_price = accounting.toFixed(
            (getDiscountPercent(item.discount) / 100) * item.base_price,
            2
          );
        }
      });
      emit("update-list", products);
      handleBack();
    };

    const getShowInfo = async () => {
      multipleTableRef.value?.clearSelection();
      const { data } = await ApiBase.getOcpsSearchProducts({
        store_id: props.form.store_id,
        website_id: props.form.website_id,
        brand_id: formData.value.brand_id,
        inventory_type: formData.value.inventory_type,
        merchant_id: formData.value.merchant_id,
        customize_channel_id: formData.value.channel_id,
        search_value: formData.value.search_value,
        page: formData.value.currentPage,
        order_tagging: props.form.order_tagging,
        // page_size: formData.value.pageSize,
      });
      if (data.code == 0) {
        data.data.items.forEach((item: DraftOrderOverviewRelationItemDraft) => {
          item.item_id = 0;
          item.base_price = Number(item.price);
          item.net_price = Number(item.price);
          // item.sales_price = Number(item.price);
          item.discount = 100;
          item.discount_type = CurrencyType.Percent;
          item.assign_ocps_sku_no = item.sku;
          item.size_label = item.size_name;
          item.color_label = item.color_name;
          item.brand = item.brand_name;
          item.product_id = item.entity_id;
        });
        formData.value.items = data.data.items;
        formData.value.total = data.data.total;
      } else {
        showServerErrorMsg(data);
      }
    };

    const debounceProductSearch = _.debounce(getShowInfo, 1000);

    const updateProductList = async () => {
      formData.value.currentPage = 1;
      await getShowInfo();
      if (
        formData.value.brand_id === "" &&
        formData.value.search_value === ""
      ) {
        options.value.isRequest = false;
      } else {
        options.value.isRequest = true;
      }
    };

    const getFormInfo = async () => {
      loading.value = true;
      Promise.all([updateProductList()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const resetForm = () => {
      // formRef.value?.resetFields();
      formData.value = {
        items: [] as DraftOrderOverviewRelationItemDraft[],
        currentPage: 1,
        pageSize: 10,
        total: 0,
        sku_ids: [] as NumberOrString[],
        products: [] as DraftOrderOverviewRelationItemDraft[],
        brand_id: "",
        search_value: "",
        merchant_id: "",
        inventory_type: "",
        channel_id: "",
      };
      options.value.isRequest = false;
    };

    const handleBack = () => {
      hideModal(addProductItemRef.value);
    };

    const init = () => {
      setTimeout(() => {
        getFormInfo();
      }, 0);
    };

    onMounted(() => {
      modalShowListener(addProductItemRef.value, () => {
        // init();
        options.value.isRequest = false;
      });
      modalHideListener(addProductItemRef.value, () => {
        resetForm();
      });
      // getTaggingData();
      cancelReadonly(false);
    });

    onUpdated(() => {
      MenuComponent.reinitialization();
    });

    return {
      t,
      CommonArrayToString,
      props,
      addProductItemRef,
      formRef,
      multipleTableRef,
      loading,
      formData,
      rules,
      options,
      scanResult,
      showSkuModal,
      cancelReadonly,
      handleReset,
      handleFilter,
      isDisabledSearch,
      getSkuListItemImages,
      toggleItemSelect,
      isChecked,
      handleSelectionChange,
      handleSelectChange,
      isSelectable,
      currentPageChange,
      searchBrandSourceItems,
      searchMerchantSourceItems,
      getSalesChannelsData,
      getFormInfo,
      updateProductList,
      submit,
      resetForm,
      setTableSelect,
      handleBack,
      isDisabledSubmit,
    };
  },
});
